<template>
  <div class="advantages">
    <div class="advantages__inner">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :navigation="{
          nextEl: '.advantages .button-next',
          prevEl: '.advantages .button-prev',
        }"
        :pagination="{
          el: '.advantages .swiper-custom-pagination',
        }"
        :loop="true"
        @swiper="onSwiper"
      >
        <swiper-slide v-for="(item, index) in slides" :key="index">
          <PromoItem
            :title="item.title"
            :desc="item.desc"
            :caption="item.caption"
            :images="item.images"
          />
        </swiper-slide>
      </swiper>
      <SwiperNavigation>
        <button type="button" class="button-prev"></button>
        <button type="button" class="button-next"></button>
      </SwiperNavigation>
    </div>
    <SwiperPagination class="swiper-custom-pagination" />
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
import PromoItem from "./PromoItem.vue";
import SwiperNavigation from "./SwiperNavigation.vue";
import SwiperPagination from "./SwiperPagination.vue";

const data = [
  {
    title: `Технология <span>VoLTE</span> звонков`,
    // eslint-disable-next-line no-irregular-whitespace
    desc: `Возможность одновременно пользоваться мобильным интернетом 4G и голосовой связью, быстрое соединение с абонентом, улучшенное качество голосовой связи`,
    images: [
      require("@/assets/advantages/9.webp"),
      require("@/assets/advantages/9@2x.webp"),
    ],
  },
  {
    title: `обмен минут на кино`,
    desc: `Меняйте минуты t2 на фильмы из каталога онлайн-кинотеатра Wink.`,
    caption: `Более 4000 фильмов и сериалов`,
    images: [
      require("@/assets/advantages/1.webp"),
      require("@/assets/advantages/1@2x.webp"),
    ],
  },
  {
    title: `не блокируем при минусе`,
    desc: `Пользуйтесь оплаченными пакетами минут и ГБ даже при отрицательном балансе.`,
    caption: ``,
    images: [
      require("@/assets/advantages/2.webp"),
      require("@/assets/advantages/2@2x.webp"),
    ],
  },
  {
    title: `вечные минуты и гб`,
    desc: `Неиспользованные вами за месяц минуты, гигабайты и SMS, включенные в тарифный план, не сгорят, а бесплатно перенесутся на следующий месяц.`,
    caption: ``,
    images: [
      require("@/assets/advantages/3.webp"),
      require("@/assets/advantages/3@2x.webp"),
    ],
  },
  {
    title: `делитесь гб`,
    desc: `Делитесь интернет-трафиком с друзьями, близкими или с любыми другими абонентами t2 пакетных тарифов по всей России.`,
    caption: `Радуйте тех, кто вам дорог!`,
    images: [
      require("@/assets/advantages/4.webp"),
      require("@/assets/advantages/4@2x.webp"),
    ],
  },
  {
    title: `По россии без доплат`,
    desc: `В поездках по России принимайте бесплатное входящие вызовы и пользуйтесь минутами и гигабайтами, включенными в тарифный план.`,
    caption: ``,
    images: [
      require("@/assets/advantages/5.webp"),
      require("@/assets/advantages/5@2x.webp"),
    ],
  },
  {
    title: `честные звонки внутри сети`,
    desc: `Общайтесь безлимитно с абонентами t2, не расходуя пакет минут, включенный в тарифный план.`,
    caption: ``,
    images: [
      require("@/assets/advantages/6.webp"),
      require("@/assets/advantages/6@2x.webp"),
    ],
  },
  {
    title: `sos-пакет`,
    desc: `Оставайтесь на связи, даже если закончились деньги на счете и нет возможности внести плату на тарифный план.`,
    caption: `Пользуйтесь бесплатно необходимыми приложениями.`,
    images: [
      require("@/assets/advantages/7.webp"),
      require("@/assets/advantages/7@2x.webp"),
    ],
  },
  {
    title: `защита от нежелательных подписок`,
    desc: `Не беспокойтесь о случайном подключении подписок в интернете. При подключении подписки вы получите код подтверждения: пока его не введете, подписки не подключится.`,
    caption: ``,
    images: [
      require("@/assets/advantages/8.webp"),
      require("@/assets/advantages/8@2x.webp"),
    ],
  },
];

export default {
  components: {
    Swiper,
    SwiperSlide,
    SwiperNavigation,
    SwiperPagination,
    PromoItem,
  },
  setup() {
    const slides = ref(data);
    return {
      slides,
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style lang="scss" scoped>
.advantages {
  position: relative;
  display: grid;
  &__inner {
    background-color: var(--colorPrimaryBg);
    border-radius: var(--radiusOuter);
    overflow: hidden;
    color: var(--colorPrimaryText);
    &:deep(.swiper) {
      height: 100%;
      .promo-item {
        @include md {
          height: 220px;
        }
      }
      .promo-item__pic {
        display: grid;
        justify-content: center;
        @include md {
          display: block;
        }
        img {
          margin: auto;
          height: auto;
          max-width: none;
          width: 420px;
          margin: 0 -110px;
          @include md {
            height: 100%;
            width: auto;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
